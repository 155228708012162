import React from 'react';
import Navbar from '../Components/Navbar';
import HeroSection from '../Components/HeroSection';
import Footer from '../Components/Footer'
const ContactUs = () => {
  return (
    <>
    <Navbar/>
    <HeroSection/>
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-10">Contact Us</h1>

      <div className="bg-white shadow-md rounded-lg p-8">
        <div className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-700 mb-2">Email:</h2>
          <p className="text-md text-gray-600">Business Email: <a href="mailto:info@birimoinsurance.com" className="text-blue-600 hover:text-blue-800">info@birimoinsurance.com</a></p>
          <p className="text-md text-gray-600">Alternative Email: <a href="mailto:birimo.insurance@gmail.com" className="text-blue-600 hover:text-blue-800">birimo.insurance@gmail.com</a></p>
        </div>

        <div className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-700 mb-2">Phone:</h2>
          <p className="text-md text-gray-600">Call us at: <a href="tel:651-315-2321" className="text-blue-600 hover:text-blue-800">651-315-2321</a></p>
          <h2 className="text-2xl font-semibold text-gray-700 mb-2">Saint Paul office Phone:</h2>
          <p className="text-md text-gray-600">Call us at: <a href="tel:651-315-2321" className="text-blue-600 hover:text-blue-800">651-332-3798</a></p>
          
        </div>

        <div className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-700 mb-2">Owner Email:</h2>
          <p className="text-md text-gray-600"><a href="mailto:abshir@birimoinsurance.com" className="text-blue-600 hover:text-blue-800">abshir@birimoinsurance.com</a></p>
        </div>

        <div className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-700 mb-2">WhatsApp:</h2>
          
          <p className="text-md text-gray-600"><a href="https://wa.me/6513152321" className="text-blue-600 hover:text-blue-800 flex"><img src='/images/whatsapp.png' className='h-6 w-6 cursor-pointer mr-2' href="https://wa.me/6513152321"/>651-315-2321</a> </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold text-gray-700 mb-2">TikTok:</h2>
          <p className="text-md text-gray-600 "><a href="https://www.tiktok.com/@birimo.insurance" className="text-blue-600 hover:text-blue-800 flex" target="_blank" rel="noopener noreferrer"><img src='/images/tiktok.png' className='h-6 w-6 cursor-pointer mr-2' href="https://www.tiktok.com/@birimo.insurance"/>@birimo.insurance</a></p>
        </div>
      </div>
    </div>
    <Footer/>
    </>
    
  );
};

export default ContactUs;
