import React from 'react';
import Navbar from '../Components/Navbar';
import HeroSection from '../Components/HeroSection';
import Footer from '../Components/Footer'
const AboutUs = () => {
  return (
    <>
    <Navbar/>
    <HeroSection/>
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-10">About Us</h1>

      <div className="bg-white shadow-md rounded-lg p-8 mb-6">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Vision:</h2>
        <p className="text-md text-gray-600">
          Our vision is straightforward yet profound: to ensure that our clients get what they need without financial strain. We understand that navigating insurance and coverage options can be overwhelming, which is why we aim to simplify the process and offer solutions that fit within our clients' budgets.
        </p>
      </div>

      <div className="bg-white shadow-md rounded-lg p-8 mb-6">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Mission:</h2>
        <p className="text-md text-gray-600">
          Our mission is twofold. Firstly, we strive to provide exceptional customer service at every touchpoint. Whether it's assisting with policy inquiries or guiding clients through claims processes, our goal is to ensure a seamless and positive experience. Secondly, we are dedicated to educating our clients about their coverage options. We believe that an informed client is an empowered one, capable of making confident decisions about their insurance needs.
        </p>
      </div>

      <div className="bg-white shadow-md rounded-lg p-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Strengths:</h2>
        <ul className="list-disc pl-5 text-md text-gray-600">
          <li className="mb-2">
            <strong>Culture:</strong> We cultivate a culture of empathy, professionalism, and integrity. Our team is dedicated to understanding and meeting the diverse needs of our clients, fostering long-term relationships built on trust and mutual respect.
          </li>
          <li className="mb-2">
            <strong>Technology Adaptation:</strong> We embrace technology as a tool to enhance our services and streamline processes. From online platforms for policy management to digital communication channels, we leverage technology to provide convenient solutions for our clients.
          </li>
          <li>
            <strong>Language:</strong> We recognize the importance of language in effective communication. Our team is proficient in various languages, ensuring that language barriers do not hinder our ability to serve our clients effectively. Whether it's English, Somali, Arabic, or any other language, we strive to make communication accessible and inclusive.
          </li>
        </ul>
      </div>
    </div>
    <Footer/>
    </>
   
  );
};

export default AboutUs;
