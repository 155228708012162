import React from "react";

// Replace 'path_to_location_image' with the actual path to your location image
const locationImage = "path_to_location_image";

const OurLocation = () => {
  return (
    <div className="container mx-auto px-4 py-8 mt-[8%]">
      <div className="mb-6">
        <a href="https://maps.app.goo.gl/W7qP1NitSHK6xWPr9?g_st=iw">
          <h2 className="text-3xl font-bold text-center text-gray-800">
            locations
          </h2>
        </a>
        <div className=" bg-white shadow rounded text-center text-2xl font-bold text-blue-600 ">
          <p>
            <h4  className="text-red-600">Minneapolis: </h4>
            200 W lake St suit #324 ( new Karmell Mall near masjid )
          </p>
          <br></br>
          <p>
            <h4 className="text-red-600">Saint Paul: </h4>
            524 Snelling AVE N #11
          </p>
        </div>
      </div>
      {/* Placeholder for the image */}
      <div className="w-full">
        {/* Uncomment the line below and add the path to your location image */}
        {/* <img src={locationImage} alt="Our Location" className="w-full h-auto object-cover"/> */}
        <div className="w-full h-[50vh] bg-gray-300 flex items-center justify-center">
          <a
            href="https://maps.app.goo.gl/W7qP1NitSHK6xWPr9?g_st=iw"
            className="w-full h-full"
          >
            <img src="/images/Map.PNG" className="w-full h-full" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default OurLocation;
