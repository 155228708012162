import React, { useState } from 'react';

const CoverageSection = ({ coverageData, setCoverageData, onSubmit }) => {
   
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCoverageData({ ...coverageData, [name]: value });
    };

    const validateCoverageData = () => {
        let tempErrors = {};
        if (!coverageData.hasInsurance) tempErrors.hasInsurance = "Insurance status is required";
        if (coverageData.hasInsurance === 'yes') {
            if (!coverageData.insuranceName) tempErrors.insuranceName = 'Insurance name is required';
            if (!coverageData.insuranceDuration) tempErrors.insuranceDuration = 'Insurance duration is required';
        }
        if (!coverageData.bodyInjuryPropertyDamage) tempErrors.bodyInjuryPropertyDamage = 'Selection is required';
        if (!coverageData.comprehensiveCollisionDeductible) tempErrors.comprehensiveCollisionDeductible = 'Selection is required';
        if (!coverageData.fullGlassCoverage) tempErrors.fullGlassCoverage = "Full glass coverage selection is required";
        if (!coverageData.needTowing) tempErrors.needTowing = "Towing service selection is required";
        if (!coverageData.needRentalCar) tempErrors.needRentalCar = "Rental car service selection is required";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateCoverageData()) {
            onSubmit(coverageData);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="w-full max-w-lg  overflow-y-auto form-scrollbar form-container">
            <h1 className="text-2xl font-bold mb-6 text-center">Coverage Section</h1>

            {/* Has Insurance */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="hasInsurance">
                    Do you have insurance?
                </label>
                <select
                    id="hasInsurance"
                    name="hasInsurance"
                    value={coverageData.hasInsurance}
                    onChange={handleChange}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="">Select Option</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
                {errors.hasInsurance && <p className="text-red-500 text-xs italic">{errors.hasInsurance}</p>}
            </div>

            {/* Insurance Name and Duration */}
            {coverageData.hasInsurance === 'yes' && (
                <>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="insuranceName">
                            Insurance Name
                        </label>
                        <input
                            id="insuranceName"
                            type="text"
                            name="insuranceName"
                            value={coverageData.insuranceName}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {errors.insuranceName && <p className="text-red-500 text-xs italic">{errors.insuranceName}</p>}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="insuranceDuration">
                            How long have you been with them?
                        </label>
                        <select
                            id="insuranceDuration"
                            name="insuranceDuration"
                            value={coverageData.insuranceDuration}
                            onChange={handleChange}
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        >
                            <option value="">Select Duration</option>
                            <option value="less_than_1_month">Less than 1 month</option>
                            <option value="less_than_3_months">Less than 3 months</option>
                            <option value="6_months">6 months</option>
                            <option value="more_than_6_months">More than 6 months</option>
                        </select>
                        {errors.insuranceDuration && <p className="text-red-500 text-xs italic">{errors.insuranceDuration}</p>}
                    </div>
                </>
            )}

            {/* Body Injury and Property Damage */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bodyInjuryPropertyDamage">
                    Body Injury and Property Damage
                </label>
                <select
                    id="bodyInjuryPropertyDamage"
                    name="bodyInjuryPropertyDamage"
                    value={coverageData.bodyInjuryPropertyDamage}
                    onChange={handleChange}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="">Select Option</option>
                    <option value="30_60_10">30/60/10</option>
                    <option value="30_60_25">30/60/25</option>
                    <option value="50_100_25">50/100/25</option>
                    <option value="50_100_50">50/100/50</option>
                    <option value="100_300_50">100/300/50</option>
                    <option value="100_300_100">100/300/100</option>
                </select>
                {errors.bodyInjuryPropertyDamage && <p className="text-red-500 text-xs italic">{errors.bodyInjuryPropertyDamage}</p>}
            </div>

            {/* Comprehensive and Collision Deductible */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="comprehensiveCollisionDeductible">
                    Comprehensive and Collision Deductible
                </label>
                <select
                    id="comprehensiveCollisionDeductible"
                    name="comprehensiveCollisionDeductible"
                    value={coverageData.comprehensiveCollisionDeductible}
                    onChange={handleChange}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="">Select Deductible</option>
                    <option value="500">500</option>
                    <option value="750">750</option>
                    <option value="1000">1000</option>
                    <option value="1500">1500</option>
                    <option value="2500">2500</option>
                </select>
                {errors.comprehensiveCollisionDeductible && <p className="text-red-500 text-xs italic">{errors.comprehensiveCollisionDeductible}</p>}
            </div>

            {/* Full Glass Coverage */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fullGlassCoverage">
                    Do you want to add full glass coverage?
                </label>
                <select
                    id="fullGlassCoverage"
                    name="fullGlassCoverage"
                    value={coverageData.fullGlassCoverage}
                    onChange={handleChange}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="">Select Option</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
                {errors.fullGlassCoverage && <p className="text-red-500 text-xs italic">{errors.fullGlassCoverage}</p>}
            </div>

            {/* Towing */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="needTowing">
                    Do you need towing?
                </label>
                <select
                    id="needTowing"
                    name="needTowing"
                    value={coverageData.needTowing}
                    onChange={handleChange}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="">Select Option</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
                {errors.needTowing && <p className="text-red-500 text-xs italic">{errors.needTowing}</p>}
            </div>

                        {/* Rental Car */}
                        <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="needRentalCar">
                    Do you need to add rental car coverage?
                </label>
                <select
                    id="needRentalCar"
                    name="needRentalCar"
                    value={coverageData.needRentalCar}
                    onChange={handleChange}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="">Select Option</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
                {errors.needRentalCar && <p className="text-red-500 text-xs italic">{errors.needRentalCar}</p>}
            </div>

            {/* Submit Button */}
            <button type="submit" className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out mt-4">
                Next
            </button>
        </form>
    );
};

export default CoverageSection;
