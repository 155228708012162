import React, { useEffect, useState } from 'react';
import { db } from '../Firebase'; // Ensure this points to your Firebase configuration file
import { collection, getDocs, doc, deleteDoc,query,orderBy } from 'firebase/firestore';

const RentersCards = () => {
    const [renters, setRenters] = useState([]);

    useEffect(() => {
        const fetchRenters = async () => {
            // Create a query against the "Renters" collection, ordering by "submittedAt" in descending order
            const rentersQuery = query(collection(db, "Renters"), orderBy("submittedAt", "desc"));
            const querySnapshot = await getDocs(rentersQuery);
            const rentersData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setRenters(rentersData);
        };

        fetchRenters();
    }, []);

    const deleteRenter = async (renterId) => {
        await deleteDoc(doc(db, "Renters", renterId));
        setRenters(renters.filter(renter => renter.id !== renterId));
    };

    const renderPersonalInfo = (renter) => (
        <div className="mb-4">
            <h4 className="text-lg font-semibold">Personal Info</h4>
            <p className="text-sm">First Name: {renter.firstName}</p>
            <p className="text-sm">Last Name: {renter.lastName}</p>
            <p className="text-sm">Email: {renter.email}</p>
            <p className="text-sm">Rental Address: {renter.rentalAddress}</p>
            <p className="text-sm">Mailing Address: {renter.mailingAddress}</p>
            <p className="text-sm">Same As Rental: {renter.sameAsRental ? 'Yes' : 'No'}</p>
        </div>
    );

    const renderCoverageData = (renter) => (
        <div className="mb-4">
            <h4 className="text-lg font-semibold">Coverage Data</h4>
            <p className="text-sm">Has Renter Insurance: {renter.coverageData.hasRenterInsurance}</p>
            <p className="text-sm">Insurance Company: {renter.coverageData.insuranceCompany || 'Not Provided'}</p>
            <p className="text-sm">Liability Coverage: {renter.coverageData.liabilityCoverage}</p>
            <p className="text-sm">Personal Property Coverage: {renter.coverageData.personalPropertyCoverage}</p>
            <p className="text-sm">Policy Effective Date: {renter.coverageData.policyEffectiveDate}</p>
            <p className="text-sm">Water Backup Coverage: {renter.coverageData.waterBackupCoverage}</p>
        </div>
    );

    const renderOccupants = (renter) => (
        <div className="mb-4">
            <h4 className="text-lg font-semibold">Occupants</h4>
            {renter.occupants.map((occupant, index) => (
                <div key={index} className="mt-2">
                    <p className="text-sm">First Name: {occupant.firstName}</p>
                    <p className="text-sm">Last Name: {occupant.lastName}</p>
                </div>
            ))}
        </div>
    );

    return (
        <div className="flex flex-wrap justify-center gap-4 m-4">
            {renters.map((renter) => (
                <div key={renter.id} className="border shadow-lg p-6 rounded-lg max-w-sm relative">
                    <img
                       src='/images/delete.png'
                        onClick={() => deleteRenter(renter.id)}
                        className="w-[30px] h-[30px] absolute top-0 right-0 m-2"
                    />
                        
                    
                    <h3 className="text-lg font-semibold mb-2">Renter Information</h3>
                    {renderPersonalInfo(renter)}
                    {renderCoverageData(renter)}
                    {renderOccupants(renter)}
                </div>
            ))}
        </div>
    );
};

export default RentersCards;


