import React from "react";

// tAKES YOU TO CUSTOMER SERVICE
const handleCustomerRedirect = () => {
  window.location.href = "https://forms.office.com/r/rC641dNKh2";
};
const CustomerSer = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center mt-10 container mx-auto px-4 py-8 mt-[6%]">
      <div className="w-full md:w-[50%] md:pl-12 flex-col items-center ">
        <h2 className="bg-red-100 text-black font-bold py-2 px-4  text-center">
          Customer service 
        </h2 >
        <div className="w-full h-full shadow-lg">
          <p className="text-md text-gray-600">
            Please feel free to reach out to our customer service team at any
            time for assitance with your corncerns, We're here to Help!
          </p>
          <br></br>
          <p className="text-md text-gray-600">
            Fadlan xor u noqo inaad la xidhiidho Qeybta adeegga macaamiisha
            wakhti kasta si lagaga cawiyo bahiidada. Riix mesha ay ku qoran
            tahay codsi Waxaan halkan u joognaa inaanu ku caawino!
          </p>
        </div>
        <div className="w-full flex items-center justify-center mt-10">
          <button
            className="bg-blue-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded-full"
            onClick={handleCustomerRedirect}
          >
            Request / Codso
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerSer;
