import React from 'react';

const ScheduleTable = () => {
  return (
    <div className="container mx-auto px-4 py-8 mt-[6%]">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-gray-800">Office Schedule</h2>
        <p className="text-md text-gray-600 mt-2">Our weekly schedule is as follows</p>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Days</th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Schedule</th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            <tr>
              <td className="text-left py-2 px-4">Monday</td>
              <td className="text-left py-2 px-4">1pm – 7PM</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="text-left py-2 px-4">Tuesday</td>
              <td className="text-left py-2 px-4">1PM – 7PM</td>
            </tr>
            <tr>
              <td className="text-left py-2 px-4">Wednesday</td>
              <td className="text-left py-2 px-4">1PM - 7PM</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="text-left py-2 px-4">Thursday</td>
              <td className="text-left py-2 px-4">11Am - 2PM</td>
            </tr>
            <tr>
              <td className="text-left py-2 px-4">Friday</td>
              <td className="text-left py-2 px-4">11AM – 2PM</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="text-left py-2 px-4">Saturday</td>
              <td className="text-left py-2 px-4">1PM – 9PM</td>
            </tr>
            <tr>
              <td className="text-left py-2 px-4">Sunday</td>
              <td className="text-left py-2 px-4">1pm – 9PM</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScheduleTable;
