import React,{useState} from 'react'
import { useNavigate,useLocation } from 'react-router-dom';
const Navbar = ({onReset}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const handleLogin = () => {
      window.location.href = 'https://birimoinsuranceagency.sharepoint.com/sites/BIAEmployees/SitePages/LearningTeamHome.aspx';
    };
    const navigate=useNavigate();
    const location = useLocation();
    const navabar=()=>{
      navigate('/')
      if (location.pathname !== '/ContactUS' && location.pathname !== '/AboutUS') {
        onReset();
    }
    }
  return (
    <nav className="bg-navcolor text-white p-4 w-full">
    <div className=" flex items-center justify-between w-full px-5">
      {/* Logo or brand name */}
      <div className="text-xl font-semibold" onClick={navabar}>
        <img src='/images/logoremovebg.png' className='h-[5vh] w-[50px]'></img>
      </div>

      {/* Navigation Links */}
      <div className="hidden md:flex items-center space-x-4  w-[30%] justify-end">
      <h1 class="text-3xl font-bold text-white">Birimo Insurance Agency</h1>

      </div>

      {/* Right side items */}
      <div className="flex items-center space-x-4">

        <div className=" cursor-pointer md:mr-10 lg:mr-10 ">
            <div className='flex flex-row items-center justify-between ' onClick={handleLogin}>
                <img src='/images/Profile2.png' className='h-7 '/>
                <h1 className='ml-2 font-semibold hidden md:inline-flex lg:inline-flex' >Employees</h1></div>
          
        </div>
        <button className=" hidden md:inline-flex lg:inline-flex text-white focus:outline-none"  onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M3 12h18M3 6h18M3 18h18" />
          </svg>
          <h1 className='font-bold ml-3'>Menu</h1>
          </button>
        {/* Mobile menu button */}
        <button className="md:hidden text-white focus:outline-none" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M3 12h18M3 6h18M3 18h18" />
          </svg>
          </button>
        </div>

    {/* SideBar starts from here */}
        <div
        className={`fixed top-0 right-0 w-[300px] h-[100vh] bg-navcolor text-white transform ease-in-out duration-300 ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        {/* Close Button */}
        <div className='w-full flex items-center justify-end pr-10 mt-[20%]'>
        <button
          onClick={() => setIsMenuOpen(false)}
          className="text-white focus:outline-none font-semibold"
          
        >
          X
        </button>
        </div>
        
        {/* Menu Content */}
        <nav className='mt-[8%]'>
          {/* Menu items */}
          <a href="/" className="block p-4 hover:bg-blue-300 font-bold">Home</a>
          <a href="/AboutUS" className="block p-4 hover:bg-blue-300 font-bold">About Us</a>
          <a href="/ContactUS" className="block p-4 hover:bg-blue-300 font-bold">Contact</a>
          <a href="/admin" className="block p-4 hover:bg-blue-300 font-bold">Admin Login</a>

          {/* ... other menu items */}
        </nav>
      </div>
      </div>
    </nav>
  );
};
  

export default Navbar
