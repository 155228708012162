import React from "react";

import HomePage from "./Pages/HomePage";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import AdminMain from './AdminPanel.js/AdminMain'
import SignInPage from "./AdminPanel.js/SignInpage";
import { AuthProvider } from "./AuthContext";

import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();

  return currentUser ? children : <Navigate to="/admin" />;
};
function App() {
  return (
    <AuthProvider>
      <Router>
  <Routes>
    <Route path="/" element={<HomePage />} /> 
    <Route path="/AboutUS" element={<AboutUs/>} /> 
    <Route path="/ContactUS" element={<ContactUs/>} /> 
    <Route
  path="/adminsection"
  element={
    <PrivateRoute>
      <AdminMain />
    </PrivateRoute>
  }
/>
    <Route path="/admin" element={<SignInPage/>} /> 
  </Routes>
 </Router>
    </AuthProvider>
  
  );
}

export default App;


