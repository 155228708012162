

import React from 'react';

const ceoImage = '/images/abshir.jpg';

const MeetOurCEO = () => {
  return (
    <div className="container px-4 py-8 flex flex-col md:flex-row  mt-[4%]">
      <div className="w-full md:w-[50%] mb-6 md:mb-0  flex items-center justify-end ">
        <img src={ceoImage} alt="CEO of Birimo Insurance" className="w-full md:w-[50%] h-auto rounded-lg shadow-lg"/>
      </div>

      <div className="w-full md:w-[50%] md:pl-12 flex-col items-center ">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">Meet Our CEO</h2>
        <p className="text-md text-gray-600">
          At the helm of Birimo Insurance is our esteemed CEO, a visionary leader with a passion for delivering exceptional insurance services. With years of industry experience, he has been instrumental in shaping the company's strategic direction, fostering a culture of excellence, and driving innovation. His dedication to client satisfaction and employee well-being exemplifies the core values of our organization. Under his guidance, Birimo Insurance has grown into a trusted name in the insurance sector, known for its integrity, reliability, and commitment to its clients.
        </p>
      </div>
    </div>
  );
};

export default MeetOurCEO;

