import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white mt-[6%] ">
      <div className="container mx-auto px-4 py-6">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <p className="mb-4 md:mb-0">© {new Date().getFullYear()} Birimo Insurance. All rights reserved.</p>
          <div className="flex flex-row">
            <a href="/ContactUS" className="mx-2 hover:text-gray-300">Contact</a>
            <span>|</span>
            <a href="/AboutUS" className="mx-2 hover:text-gray-300">About Us</a>
            <span>|</span>
            <a href="/" className="mx-2 hover:text-gray-300">Home</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
