import React from 'react'

const HeroSection = () => {
  return (
    // <div className='flex w-full bg-black items-center justify-center p-3 text-white text-blue-300'>
    //   <a href='#' className='font-bold'>Our Commitment to Diversity, Equity and Inclusion</a>
    // </div>
    <div className='flex flex-col justify-center items-center w-full bg-black p-3 text-white '>
    <a href='#' className='font-bold text-center text-blue-300'>
    We are local, We are in Minneapolis
    </a>
  </div>
  )
}

export default HeroSection
