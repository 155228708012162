

import React, { useState } from 'react';
import { db } from '../Firebase';
import { collection, addDoc } from 'firebase/firestore';
const AdditionalOccupantsForm = ({ onSubmit, formData,coverageFormData }) => {
    const [agreed, setAgreed] = useState(false);
    const [occupants, setOccupants] = useState([
        { firstName: '', lastName: '' }
    ]);

    const handleAgreementChange = (e) => {
        setAgreed(e.target.checked);
    };

    const handleOccupantChange = (index, e) => {
        const updatedOccupants = [...occupants];
        updatedOccupants[index][e.target.name] = e.target.value;
        setOccupants(updatedOccupants);
    };

    const addOccupant = () => {
        if (occupants.length < 5) {
            setOccupants([...occupants, { firstName: '', lastName: '' }]);
        }
    };

    const deleteOccupant = (index) => {
        const updatedOccupants = occupants.filter((_, idx) => idx !== index);
        setOccupants(updatedOccupants);
    };

  

    const handleSubmit = async(event) => {
        event.preventDefault();
        if (agreed) {
            onSubmit(occupants);
            const timestamp = new Date(); // Captures the current date and time
            const combinedData = {
                ...formData,
                coverageData: coverageFormData,
                occupants: occupants, // Include the occupants data
                submittedAt: timestamp // Add the timestamp here
            };
            
            try {
                await addDoc(collection(db, "Renters"), combinedData);
                 // Show the modal on successful submission
            } catch (error) {
                console.error("Error adding document: ", error);
            }
        } else {
            alert('Please agree to the terms and conditions.');
        }
    };
    

    return (
        <div className="flex flex-col items-center">
            <form onSubmit={handleSubmit} className="w-full max-w-lg p-4 overflow-y-auto form-scrollbar form-container">
                <h1 className="text-2xl font-bold mb-6 text-center">Terms and Conditions</h1>
                
                {/* Additional Occupants */}
                <h2 className="text-xl font-bold mb-4">Additional Occupant(s):</h2>
                {occupants.map((occupant, index) => (
                    <div key={index} className="flex items-center mb-3">
                        <input
                            className="shadow border rounded py-2 px-3 text-gray-700 leading-tight mr-2 flex-1"
                            name="firstName"
                            placeholder="First Name"
                            value={occupant.firstName}
                            onChange={(e) => handleOccupantChange(index, e)}
                        />
                        <input
                            className="shadow border rounded py-2 px-3 text-gray-700 leading-tight mr-2 flex-1"
                            name="lastName"
                            placeholder="Last Name"
                            value={occupant.lastName}
                            onChange={(e) => handleOccupantChange(index, e)}
                        />
                        {occupants.length > 1 && (
                            <button
                                type="button"
                                onClick={() => deleteOccupant(index)}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                            >
                                Del
                            </button>
                        )}
                    </div>
                ))}

                {occupants.length < 5 && (
                    <button
                        type="button"
                        onClick={addOccupant}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Add Occupant
                    </button>
                )}

                {/* Terms and Conditions Text */}
                <div className="my-4">
                    <p className="mb-2">Please read and agree to the terms and conditions:</p>
                    <ul className="list-disc ml-6 mb-4">
                        <li className='mb-5'>I consent to receive SMS/calls/email offering quotes from Birimo Insurance Agency LLC.</li>
                        <li className='mb-5'>We collect data for insurance purposes.</li>
                        <li className='mb-5'>We don’t and will not sell your personal info.</li>
                        <li className='mb-5'>We will share the data with carriers to get a quote for you.</li>
                    </ul>
                </div>

                {/* Agreement Checkbox */}
                <div className="flex items-center mb-4">
                    <input
                        type="checkbox"
                        checked={agreed}
                        onChange={handleAgreementChange}
                        className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <label className="ml-2 text-sm" htmlFor="agreement">
                        I agree to the terms and conditions
                    </label>
                </div>

                {/* Submit Button */}
                <button
                    type="submit"
                    disabled={!agreed}
                    className={`w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${!agreed ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    Submit
                </button>
            </form>
        </div>
    );
};

export default AdditionalOccupantsForm;

