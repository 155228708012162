// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCyoto0edQ_2P80dEXvjHMfY6OStvlOhXU",
  authDomain: "insurance-c7d28.firebaseapp.com",
  projectId: "insurance-c7d28",
  storageBucket: "insurance-c7d28.appspot.com",
  messagingSenderId: "986236844865",
  appId: "1:986236844865:web:88345a5a9e260c6dea3fc0",
  measurementId: "G-CXS4YRPT46"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);
export const auth = getAuth(app);
