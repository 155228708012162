import React from 'react'

const Heading = () => {
  return (
    <div className='w-full flex flex-col items-center justify-center mt-10'>
      

    <h1 className="text-lg  font-bold text-black text-center mt-10">
    Choose your insurance product
    </h1> 

    </div>
  )
}

export default Heading
