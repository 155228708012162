

import React, { useState } from 'react';
import CardDetailsForm from '../Components/CarDetailsForm'
import HouseholdMemberForm from './HouseholdMemberForm';
import CoverageSection from './CoverageSection';
import TermsAndConditions from './TermsAndConditions';
import Modal from './Model';
const InfoForm = ({ onReset }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        maritalStatus: '',
        dlNumber: '',
        dlState: '',
        dlStatus: '',
        phone: '',
        email: '',
        address: '',
        apartment: '',
        city: '',
        state: '',
        zipCode: '',
        gender:'',
        spouseName: '',
    spouseDlNumber: '',
    spouseDlStatus: '',
    spouseDlState: '',
    spouseDob: '',
    carDetails: [
        {
          hasVin: '',
          carMake: '',
          carModel: '',
          carYear: '',
          vinNumber: '',
          carOwnership: ''
        }
      ],

      spouseDetails: {
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        dlNumber: '',
        dlState: '',
    },
    householdMembers: [],
    coverageData: {
        hasInsurance: '',
        insuranceName: '',
        insuranceDuration: '',
        bodyInjuryPropertyDamage: '',
        comprehensiveCollisionDeductible: '',
        fullGlassCoverage: '',
        needTowing: '',
        needRentalCar: ''
    },

    });
    const [errors, setErrors] = useState({});
    const [currentSection, setCurrentSection] = useState(1);
    const [state2Filled, setState2Filled] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
   
    const validateForm = () => {
        let tempErrors = {};
    
        if (currentSection === 1) {
            // Validate only the fields in the first section
            if (!formData.firstName) tempErrors.firstName = "First Name is required";
            if (!formData.lastName) tempErrors.lastName = "Last Name is required";
            if (!formData.dob) tempErrors.dob = "Date of Birth is required";
            if (!formData.maritalStatus) tempErrors.maritalStatus = "Marital Status is required";
            if (!formData.dlNumber) tempErrors.dlNumber = "Driver's License Number is required";
            if (!formData.dlState) tempErrors.dlState = "Driver's License State is required";
            if (!formData.dlStatus) tempErrors.dlStatus = "Driver's License Status is required";
            if (!formData.phone.match(/^\d{3}-\d{3}-\d{4}$/)) tempErrors.phone = "Phone number is invalid (format: xxx-xxx-xxxx)";
            if (!/\S+@\S+\.\S+/.test(formData.email)) tempErrors.email = "Email is invalid";
            if (!formData.address) tempErrors.address = "Address is required";
            if (!formData.city) tempErrors.city = "City is required";
            if (!formData.state) tempErrors.state = "State is required";
            if (!formData.zipCode.match(/^[0-9]{5}$/)) tempErrors.zipCode = "Zip Code is invalid";
            if (!formData.gender) tempErrors.gender = "Gender is required";
            // if (!formData.apartment) tempErrors.apartment = "Apartment number is required";

        } else if (currentSection === 2) {
            // Validate only the fields in the second section
            if (!formData.spouseName) tempErrors.spouseName = "Spouse's Name is required";
            if (!formData.spouseDlNumber) tempErrors.spouseDlNumber = "Spouse's Driver's License Number is required";
            if (!formData.spouseDlStatus) tempErrors.spouseDlStatus = "Spouse's Driver's License Status is required";
            if (!formData.spouseDlState) tempErrors.spouseDlState = "Spouse's Driver's License State is required";
            if (!formData.spouseDob) tempErrors.spouseDob = "Spouse's Date of Birth is required";
        }
    
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };
    

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            console.log("Form Data Submitted: ", formData);
            // Handle form submission here
        }
    }

    
    const handleChange = (e) => {
        const { name, value } = e.target;
    
        let formattedValue = value;
        // Check if the input field is phone
        if (name === 'phone') {
            // Remove all non-digit characters
            const numbers = value.replace(/[^\d]/g, '');
            // Format the phone number
            formattedValue = numbers.replace(/(\d{3})(\d{1,3})?(\d{1,4})?/, (match, p1, p2, p3) => {
                if (p3) {
                    return `${p1}-${p2}-${p3}`;
                } else if (p2) {
                    return `${p1}-${p2}`;
                } else {
                    return p1;
                }
            });
        }
    
        setFormData({ ...formData, [name]: formattedValue });
    }
    

    const usStates = [
        "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia",
        "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland",
        "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey",
        "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina",
        "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
    ];
    

    const handleNextSection = () => {
        const isFormValid = validateForm();
    
        if (!isFormValid) {
            return; // If the form is not valid, do not proceed
        }
    
        if (currentSection === 1) {
            if (formData.maritalStatus === 'married') {
                setCurrentSection(2); // Proceed to the spouse details section
                setState2Filled(true);
            } else {
                setCurrentSection(3); // Proceed directly to car details for unmarried users
            }
        } else if (currentSection === 2) {
            setCurrentSection(3); // Proceed to car details after completing spouse details
        } else if (currentSection === 3) {
            setCurrentSection(4); // Proceed to household member form after car details
            console.log("Current Section after update:", 4);
        }
        // You can add more else if blocks if you have more sections
    };
    
   
    const handleCarDetailsSubmit = (newCarDetails) => {
        // Merge new car details with existing formData
        setFormData({ ...formData, carDetails: newCarDetails });
        setCurrentSection(4); // Move to the next section
      };
      
    

    
   
    const handleHouseholdSubmit = (householdData) => {
        // Update formData state with householdData
        setFormData({ ...formData, ...householdData });
        setCurrentSection(5); // Move to the next section
    };
      

   

    const handleCoverageSubmit = (newCoverageData) => {
        setFormData({ ...formData, coverageData: newCoverageData });
        setCurrentSection(6); // Move to the next section
    };

    
    const handleBackSection = () => {
        if (currentSection > 1) {
            if (currentSection === 3 && !state2Filled) {
                // If moving back from state3 to state2 and state2 is not filled, go to state1
                setCurrentSection(1);
            } else {
                setCurrentSection(currentSection - 1);
            }
        }
    };
    
    const calculateCompletionPercentage = () => {
        return ((currentSection - 1) / 5) * 100;
    };

    const handleTermsSubmit=()=>{
        console.log('done')
        setIsModalOpen(true);
    }
    const closeModal = () => {
        setIsModalOpen(false);
        onReset();
    };
    return (
        <>
         <Modal 
                isOpen={isModalOpen} 
                message="Thanks for your submission. We will follow up shortly." 
                onClose={closeModal} 
            />
        <div className='flex px-4'>
        {currentSection !== 1 && !(currentSection === 2 && !state2Filled) && (
    <button 
        onClick={handleBackSection}
        className=" text-white font-bold  px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
    >
        <img src='/images/backarrow.png' className='h-7 w-7'/>
    </button>
)}
<div className='w-full mt-[1%]'>
<div className="w-full bg-gray-300 rounded-full h-2.5 mt-4">
            <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${calculateCompletionPercentage()}%` }}></div>
        </div>
        <p className="text-center text-sm my-2">{Math.round(calculateCompletionPercentage())}% Completed</p>
</div>

        </div>
       
        {
    currentSection === 1 && (
         <div className="flex flex-col justify-center items-center h-[85vh]">
            <form onSubmit={handleSubmit} className="w-full max-w-lg h-[70vh] overflow-y-auto form-scrollbar form-container">
           
                <h1 className="text-2xl font-bold mb-6 text-center">Auto Insurance Form</h1>

                {/* First Name */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">
                        First Name
                    </label>
                    <input 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        id="firstName" 
                        type="text" 
                        placeholder="Enter First Name" 
                        name="firstName"
                        onChange={handleChange}
                        value={formData.firstName}
                    />
                    {errors.firstName && <p className="text-red-500 text-xs italic">{errors.firstName}</p>}
                </div>

                {/* Last Name */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastName">
                        Last Name
                    </label>
                    <input 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        id="lastName" 
                        type="text" 
                        placeholder="Enter Last Name" 
                        name="lastName"
                        onChange={handleChange}
                        value={formData.lastName}
                    />
                    {errors.lastName && <p className="text-red-500 text-xs italic">{errors.lastName}</p>}
                </div>

                {/* Date of Birth */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dob">
                        Date of Birth
                    </label>
                    <input 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        id="dob" 
                        type="date" 
                        name="dob"
                        onChange={handleChange}
                        value={formData.dob}
                    />
                    {errors.dob && <p className="text-red-500 text-xs italic">{errors.dob}</p>}
                </div>

                <div className="mb-4">
    <label htmlFor="gender" className="block text-gray-700 text-sm font-bold mb-2">
        Gender
    </label>
    <select
        id="gender"
        name="gender"
        value={formData.gender}
        onChange={handleChange}
        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    >
        <option value="">Select Gender</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="other">Other</option>
    </select>
    {errors.gender && <p className="text-red-500 text-xs italic">{errors.gender}</p>}
</div>

                {/* Marital Status */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="maritalStatus">
                        Marital Status
                    </label>
                    <select 
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        id="maritalStatus" 
                        name="maritalStatus"
                        onChange={handleChange}
                        value={formData.maritalStatus}
                    >
                        <option value="">Select Marital Status</option>
    <option value="single">Single</option>
    <option value="married">Married</option>
    <option value="separated">Separated</option>
    <option value="divorced">Divorced</option>
                    </select>
                    {errors.maritalStatus && <p className="text-red-500 text-xs italic">{errors.maritalStatus}</p>}
                </div>

                {/* DL Number */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dlNumber">
                        Driver's License Number
                    </label>
                    <input 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        id="dlNumber" 
                        type="text" 
                        placeholder="Enter DL Number" 
                        name="dlNumber"
                        onChange={handleChange}
                        value={formData.dlNumber}
                    />
                    {errors.dlNumber && <p className="text-red-500 text-xs italic">{errors.dlNumber}</p>}
                </div>

                {/* DL State */}
                <div className="mb-4">
    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dlState">
        Driver's License State
    </label>
    <select 
        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
        id="dlState" 
        name="dlState"
        onChange={handleChange}
        value={formData.dlState}
    >
        <option value="">Select DL State</option>
        {usStates.map((state, index) => (
            <option key={index} value={state}>{state}</option>
        ))}
    </select>
    {errors.dlState && <p className="text-red-500 text-xs italic">{errors.dlState}</p>}
</div>

                {/* DL Status */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dlStatus">
                        Driver's License Status
                    </label>
                    <select 
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        id="dlStatus" 
                        name="dlStatus"
                        onChange={handleChange}
                        value={formData.dlStatus}
                    >
                         <option value="">Select DL Status</option>
    <option value="license">License</option>
    <option value="permit">Permit</option>
    <option value="other">Other</option>
                    </select>
                    {errors.dlStatus && <p className="text-red-500 text-xs italic">{errors.dlStatus}</p>}
                </div>

                {/* Phone */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                        Phone
                    </label>
                    <input 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        id="phone" 
                        type="tel" 
                        placeholder="xxx-xxx-xxxx" 
                        name="phone"
                        onChange={handleChange}
                        value={formData.phone}
                    />
                    {errors.phone && <p className="text-red-500 text-xs italic">{errors.phone}</p>}
                </div>

                {/* Email */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                        Email
                    </label>
                    <input 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        id="email" 
                        type="email" 
                        placeholder="Enter Email Address" 
                        name="email"
                        onChange={handleChange}
                        value={formData.email}
                    />
                    {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
                </div>


                <div className="mb-4">
    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
        Address
    </label>
    <input 
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
        id="address" 
        type="text" 
        placeholder="Enter Address" 
        name="address"
        onChange={handleChange}
        value={formData.address}
    />
    {errors.address && <p className="text-red-500 text-xs italic">{errors.address}</p>}
</div>

<div className="mb-4">
    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="zipCode">
        Zip Code
    </label>
    <input 
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
        id="zipCode" 
        type="text" 
        placeholder="Enter Zip Code" 
        name="zipCode"
        onChange={handleChange}
        value={formData.zipCode}
    />
    {errors.zipCode && <p className="text-red-500 text-xs italic">{errors.zipCode}</p>}
</div>


<div className="mb-4">
    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="apartment">
        Apartment
    </label>
    <input 
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
        id="apartment" 
        type="text" 
        placeholder="Enter Apartment Number" 
        name="apartment"
        onChange={handleChange}
        value={formData.apartment}
    />
    {errors.apartment && <p className="text-red-500 text-xs italic">{errors.apartment}</p>}
</div>

<div className="mb-4">
    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="city">
        City
    </label>
    <input 
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
        id="city" 
        type="text" 
        placeholder="Enter City" 
        name="city"
        onChange={handleChange}
        value={formData.city}
    />
    {errors.city && <p className="text-red-500 text-xs italic">{errors.city}</p>}
</div>

<div className="mb-4">
    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="state">
        State
    </label>
    <select 
        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
        id="state" 
        name="state"
        onChange={handleChange}
        value={formData.state}
    >
        <option value="">Select State</option>
        {usStates.map((state, index) => (
            <option key={index} value={state}>{state}</option>
        ))}
    </select>
    {errors.state && <p className="text-red-500 text-xs italic">{errors.state}</p>}
</div>


               
                      
                        </form>
                        <button 
                    type="button" 
                    onClick={handleNextSection}
                    className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out mt-4"
                >
                    Next
                </button>
                        </div>)}

           


                        {
    currentSection === 2 && (
        <div className="flex flex-col justify-center items-center h-[85vh]">
            <form onSubmit={handleSubmit} className="w-full max-w-lg">
            <h1 className="text-2xl font-bold mb-6 text-center">Spouse Details</h1>

            {/* Spouse's Name */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="spouseName">
                    Spouse's Name
                </label>
                <input 
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                    id="spouseName" 
                    type="text" 
                    placeholder="Enter Spouse's Name" 
                    name="spouseName"
                    onChange={handleChange}
                    value={formData.spouseName}
                />
                {errors.spouseName && <p className="text-red-500 text-xs italic">{errors.spouseName}</p>}
            </div>

            {/* Spouse's Driver's License Number */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="spouseDlNumber">
                    Spouse's Driver's License Number
                </label>
                <input 
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                    id="spouseDlNumber" 
                    type="text" 
                    placeholder="Enter Spouse's DL Number" 
                    name="spouseDlNumber"
                    onChange={handleChange}
                    value={formData.spouseDlNumber}
                />
                {errors.spouseDlNumber && <p className="text-red-500 text-xs italic">{errors.spouseDlNumber}</p>}
            </div>

            {/* Spouse's Driver's License State */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="spouseDlState">
                    Spouse's Driver's License State
                </label>
                <select 
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                    id="spouseDlState" 
                    name="spouseDlState"
                    onChange={handleChange}
                    value={formData.spouseDlState}
                >
                    <option value="">Select DL State</option>
                    {usStates.map((state, index) => (
                        <option key={index} value={state}>{state}</option>
                    ))}
                </select>
                {errors.spouseDlState && <p className="text-red-500 text-xs italic">{errors.spouseDlState}</p>}
            </div>

            {/* Spouse's Driver's License Status */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="spouseDlStatus">
                    Spouse's Driver's License Status
                </label>
                <select 
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                    id="spouseDlStatus" 
                    name="spouseDlStatus"
                    onChange={handleChange}
                    value={formData.spouseDlStatus}
                >
                    <option value="">Select DL Status</option>
                    <option value="valid">Valid</option>
                    <option value="expired">Expired</option>
                    <option value="suspended">Suspended</option>
                </select>
                {errors.spouseDlStatus && <p className="text-red-500 text-xs italic">{errors.spouseDlStatus}</p>}
            </div>

            {/* Spouse's Date of Birth */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="spouseDob">
                    Spouse's Date of Birth
                </label>
                <input 
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                    id="spouseDob" 
                    type="date" 
                    name="spouseDob"
                    onChange={handleChange}
                    value={formData.spouseDob}
                />
                {errors.spouseDob && <p className="text-red-500 text-xs italic">{errors.spouseDob}</p>}
            </div>

            
        </form>
        <button 
        onClick={handleNextSection} 
        type="submit" className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out mt-4 ">
                Next
            </button>
        </div>
        
    )
}




{currentSection === 3 && (
  <div className="flex flex-col justify-center items-center h-[85vh]">
    <CardDetailsForm
      carDetails={formData.carDetails}
      onSubmit={handleCarDetailsSubmit}
      usStates={usStates}
    />
  </div>
)}






{currentSection === 4 && (
                <div className="flex flex-col justify-center items-center h-[85vh]">
                    <HouseholdMemberForm
                        maritalStatus={formData.maritalStatus}
                        setMaritalStatus={(value) => setFormData({ ...formData, maritalStatus: value })}
                        spouseDetails={formData.spouseDetails}
                        setSpouseDetails={(details) => setFormData({ ...formData, spouseDetails: details })}
                        householdMembers={formData.householdMembers}
                        setHouseholdMembers={(members) => setFormData({ ...formData, householdMembers: members })}
                        onSubmit={handleHouseholdSubmit}
                        usStates={usStates}
                    />
                </div>
            )}




             {currentSection === 5 && (
                <div className="flex flex-col justify-center items-center h-[85vh]">
                    <CoverageSection
                        coverageData={formData.coverageData}
                        setCoverageData={(newData) => setFormData({ ...formData, coverageData: newData })}
                        onSubmit={handleCoverageSubmit}
                    />
                </div>
            )}

{currentSection === 6 && (
                <div className="flex flex-col justify-center items-center h-[85vh]">
                    <TermsAndConditions onSubmit={handleTermsSubmit} formData={formData}/>
                </div>
            )}
        </>
       

    )}

    
    export default InfoForm


