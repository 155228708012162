

import React, { useState } from 'react';
import CoverageSectionForm from './CoverageSectionRenters';
import TermsandCondition from '../Components/TermsAndConditionRenter'
import Modal from './Model';

const PersonalInfoForm = ({ onReset }) => {

    const [currentStep, setCurrentStep] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    
    const handleBackButton = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const calculateCompletionPercentage = () => {
        // Assuming there are 2 steps in total
        const totalSteps = 2;
        // Subtract 1 from currentStep to consider the initial step as 0% completion
        return ((currentStep - 1) / totalSteps) * 100;
    };
    
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        rentalAddress: '',
        mailingAddress: '',
        sameAsRental: true
    });
    const [coverageFormData, setCoverageFormData] = useState({
        policyEffectiveDate: '',
        hasRenterInsurance: '',
        insuranceCompany: '',
        personalPropertyCoverage: '',
        liabilityCoverage: '',
        waterBackupCoverage: ''
    });

    



    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        let formattedValue = value;
    
        // Check if the input field is phone
        if (name === 'phone') {
            // Remove all non-digit characters
            const numbers = value.replace(/[^\d]/g, '');
    
            // Format the phone number
            formattedValue = numbers.replace(/(\d{3})(\d{1,3})?(\d{1,4})?/, (match, p1, p2, p3) => {
                if (p3) {
                    return `${p1}-${p2}-${p3}`;
                } else if (p2) {
                    return `${p1}-${p2}`;
                } else {
                    return p1;
                }
            });
        }
    
        setFormData({ ...formData, [name]: formattedValue });
    };
    

    const handleCheckboxChange = () => {
        setFormData({
            ...formData,
            sameAsRental: !formData.sameAsRental,
            ...(formData.sameAsRental && { mailingAddress: formData.rentalAddress })
        });
    };

    const validateForm = () => {
        let tempErrors = {};
        if (!formData.firstName.trim()) tempErrors.firstName = 'First name is required';
        if (!formData.lastName.trim()) tempErrors.lastName = 'Last name is required';
        if (!formData.phone.match(/^\d{3}-\d{3}-\d{4}$/)) tempErrors.phone = 'Phone number is invalid (format: xxx-xxx-xxxx)';
        if (!/\S+@\S+\.\S+/.test(formData.email)) tempErrors.email = 'Email is invalid';
        if (!formData.rentalAddress.trim()) tempErrors.rentalAddress = 'Rental address is required';
        if (!formData.sameAsRental && !formData.mailingAddress.trim()) tempErrors.mailingAddress = 'Mailing address is required';
    
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };
    

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            setCurrentStep(2);
        }
    };

    const handleCoverageSectionSubmit = (coverageData) => {
        // Handle the submission of the CoverageSectionForm data
        console.log('yes me clicked');
        setCoverageFormData(coverageData);
        setCurrentStep(3);
        // Move to next step or finish the form process
    };

    


    const handleTermsandConditions =() => {
      
       
            setIsModalOpen(true);
        
    };


    const closeModal = () => {
        setIsModalOpen(false);
        onReset();
       
    };
    return (
        <>
        <Modal 
                isOpen={isModalOpen} 
                message="Thanks for your submission. We will follow up shortly." 
                onClose={closeModal} 
            />
        
            <div className='flex px-4'>
    {currentStep > 1 && (
        <button onClick={handleBackButton} className="text-white font-bold  px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
            <img src='/images/backarrow.png' className='h-7 w-7'/>
        </button>
    )}
    <div className="w-full mt-[1%]">
        <div className="w-full bg-gray-300 rounded-full h-2.5 mt-3">
            <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${calculateCompletionPercentage()}%` }}></div>
        </div>
        <p className="text-center text-sm my-2">{Math.round(calculateCompletionPercentage())}% Completed</p>
    </div>
</div>

        <div className="flex flex-col justify-center items-center h-[85vh]">



{currentStep === 1 && <>
    <form onSubmit={handleSubmit} className="w-full max-w-lg  overflow-y-auto form-scrollbar form-container">
            {/* First Name */}
            <div className="mb-4">
                <label htmlFor="firstName" className="block text-gray-700 text-sm font-bold mb-2">
                    First Name
                </label>
                <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    onChange={handleChange}
                    value={formData.firstName}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight"
                />
                {errors.firstName && <p className="text-red-500 text-xs italic">{errors.firstName}</p>}
            </div>

            {/* Last Name */}
            <div className="mb-4">
                <label htmlFor="lastName" className="block text-gray-700 text-sm font-bold mb-2">
                    Last Name
                </label>
                <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    onChange={handleChange}
                    value={formData.lastName}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight"
                />
                {errors.lastName && <p className="text-red-500 text-xs italic">{errors.lastName}</p>}
            </div>

            {/* Phone Number */}
            <div className="mb-4">
                <label htmlFor="phone" className="block text-gray-700 text-sm font-bold mb-2">
                    Phone #
                </label>
                <input
                    id="phone"
                    name="phone"
                    type="tel"
                    onChange={handleChange}
                    value={formData.phone}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight"
                />
                {errors.phone && <p className="text-red-500 text-xs italic">{errors.phone}</p>}
            </div>

            {/* Email */}
            <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
                    Email
                </label>
                <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={handleChange}
                    value={formData.email}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight"
                />
                {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
            </div>

            {/* Rental Address */}
            <div className="mb-4">
                <label htmlFor="rentalAddress" className="block text-gray-700 text-sm font-bold mb-2">
                    Rental Address
                </label>
                <input
                    id="rentalAddress"
                    name="rentalAddress"
                    type="text"
                    onChange={handleChange}
                    value={formData.rentalAddress}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight"
                />
                {errors.rentalAddress && <p className="text-red-500 text-xs italic">{errors.rentalAddress}</p>}
            </div>

            {/* Checkbox for Same As Rental Address */}
          

            {/* Mailing Address */}
            {!formData.sameAsRental && (
                <div className="mb-4">
                    <label htmlFor="mailingAddress" className="block text-gray-700 text-sm font-bold mb-2">
                        Mailing Address
                    </label>
                    <input
                        id="mailingAddress"
                        name="mailingAddress"
                        type="text"
                        onChange={handleChange}
                        value={formData.mailingAddress}
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight"
                    />
                    {errors.mailingAddress && <p className="text-red-500 text-xs italic">{errors.mailingAddress}</p>}
                </div>
            )}

<div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    <input
                        type="checkbox"
                        checked={formData.sameAsRental}
                        onChange={handleCheckboxChange}
                        className="mr-2"
                    />
                    Mailing Address same as Rental Address
                </label>
            </div>

            {/* Submit Button */}
            <button type="submit" className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded">
                Next
            </button>
        </form>
</>}
       

{currentStep === 2 && <CoverageSectionForm onSubmit={handleCoverageSectionSubmit}   formData={coverageFormData} 
                    setFormData={setCoverageFormData} 
                    errors={errors} />}
{currentStep === 3 && <TermsandCondition onSubmit={handleTermsandConditions} formData={formData} coverageFormData={coverageFormData}  />}

        </div>
        </>
        
    );
};

export default PersonalInfoForm;
