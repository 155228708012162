


import React, { useEffect, useState } from 'react';
import { db } from '../Firebase'; // Ensure this points to your Firebase configuration file
import { collection, getDocs, doc, deleteDoc, query , orderBy } from 'firebase/firestore';

const ClientCards = () => {
    const [clients, setClients] = useState([]);

    

    useEffect(() => {
        const fetchClients = async () => {
            // Adjust the query to order by 'submittedAt' timestamp in descending order
            const clientsQuery = query(collection(db, "insuranceForms"), orderBy("submittedAt", "desc"));
            const querySnapshot = await getDocs(clientsQuery);
            const clientsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setClients(clientsData); // Update state with ordered data
        };
    
        fetchClients();
    }, []);
    
    

    const deleteClient = async (clientId) => {
        await deleteDoc(doc(db, "insuranceForms", clientId));
        // Removing the deleted client from the state
        setClients(clients.filter(client => client.id !== clientId));
    };

    const formatKey = (key) => {
        return key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
    };

    const hasData = (data) => {
        return data && Object.keys(data).length > 0 && Object.values(data).some(x => x !== null && x !== '');
    };

    const renderPersonalDetails = (client) => {
        const personalDetailsKeys = [
            'firstName', 'lastName', 'dob', 'maritalStatus', 'dlNumber', 'dlState', 'dlStatus',
            'phone', 'email', 'address', 'apartment', 'city', 'state', 'zipCode', 'gender',
        ];

        return personalDetailsKeys.map(key => (
            client[key] ? <p key={key} className="text-sm">{`${formatKey(key)}: ${client[key]}`}</p> : null
        ));
    };

    const renderCarDetails = (cars) => {
        return cars.map((car, index) => (
            <div key={index} className="mt-2">
                <h4 className="text-lg font-semibold">Car {index + 1}</h4>
                {Object.entries(car).map(([key, value]) => (
                    <p key={key} className="text-sm">{`${formatKey(key)}: ${value}`}</p>
                ))}
            </div>
        ));
    };

    const renderHouseholdMembers = (members) => {
        return members.map((member, index) => (
            <div key={index} className="mt-2">
                <h4 className="text-lg font-semibold">Household Member {index + 1}</h4>
                {Object.entries(member).map(([key, value]) => (
                    <p key={key} className="text-sm">{`${formatKey(key)}: ${value}`}</p>
                ))}
            </div>
        ));
    };

    const renderSection = (client, section) => {
        if (!hasData(client[section])) {
            return null; // Don't render this section if it's empty
        }

        return (
            <div>
                <h4 className="text-lg font-semibold mt-4">{formatKey(section)}</h4>
                <div className="ml-4">
                    {section === 'carDetails' ? renderCarDetails(client[section]) : 
                     section === 'householdMembers' ? renderHouseholdMembers(client[section]) : 
                     Object.entries(client[section]).map(([key, value]) => (
                        <p key={key} className="text-sm">{`${formatKey(key)}: ${value}`}</p>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="flex flex-wrap justify-center gap-4 m-4">
            {clients.map((client) => (
                <div key={client.id} className="border shadow-lg p-6 rounded-lg max-w-sm relative">
                    <img
                        src='/images/delete.png'
                        onClick={() => deleteClient(client.id)}
                        className="w-[30px] h-[30px] absolute top-0 right-0 m-2"
                    />
                    
                    <h3 className="text-lg font-semibold mb-2">Client Information</h3>
                    <div className="mb-4">
                        <h4 className="text-lg font-semibold">Personal Details</h4>
                        {renderPersonalDetails(client)}
                    </div>
                    {renderSection(client, 'carDetails')}
                    {renderSection(client, 'spouseDetails')}
                    {renderSection(client, 'householdMembers')}
                    {renderSection(client, 'coverageData')}
                </div>
            ))}
        </div>
    );
};

export default ClientCards;
