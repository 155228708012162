import React, { useState } from 'react';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../Firebase';
const TermsAndConditions = ({ onSubmit,formData }) => {
    const [agreed, setAgreed] = useState(false);

    const handleAgreementChange = (e) => {
        setAgreed(e.target.checked);
    };


    

    


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (agreed) {
            const formDataWithTimestamp = {
                ...formData,
                submittedAt: new Date() // Add the current timestamp
            };
            try {
                await addDoc(collection(db, "insuranceForms"), formDataWithTimestamp);
                onSubmit(); // Callback function to signal submission completion
            } catch (e) {
                console.error("Error adding document: ", e);
            }
        }
    };
    
    
    
    return (
        <form onSubmit={handleSubmit} className="w-full max-w-lg">
            <h1 className="text-2xl font-bold mb-6 text-center">Terms and Conditions</h1>
            
            {/* Terms and Conditions Text */}
            <ul className="list-disc ml-6 mb-4">
                <li className='mb-5'>I consent to receive SMS/calls/email at any time offering quotes from Birimo Insurance Agency LLC.</li>
                <li className='mb-5'>We collect data for insurance purposes.</li>
                <li className='mb-5'>We don’t and will not sell your personal info.</li>
                <li className='mb-5'>We will share the data we collect with the carriers we work with to get a quote for you.</li>
            </ul>

            {/* Agreement Checkbox */}
            <div className="mb-4">
                <label className="inline-flex items-center">
                    <input
                        type="checkbox"
                        checked={agreed}
                        onChange={handleAgreementChange}
                        className="form-checkbox text-teal-600"
                    />
                    <span className="ml-2">I agree to the terms and conditions</span>
                </label>
            </div>

            {/* Submit Button */}
            <button
                type="submit"
                disabled={!agreed}
                className={`bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out mt-4 ${!agreed ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
                Submit
            </button>
        </form>
    );
};

export default TermsAndConditions;
