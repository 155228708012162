import React , { useState,useEffect }from 'react'
import Navbar from '../Components/Navbar'
import HeroSection from '../Components/HeroSection'
import Heading from '../Components/Heading'
import CardSection from '../Components/CardSection'
import InfoForm from '../Components/InfoForm'
import RentersPage from '../Components/RentersPage'
import Ceo from '../Components/Ceo'
import OfficeTiming from '../Components/OfficeTimings'
import Location from '../Components/Location'
import Footer from '../Components/Footer'
import CustomerSer from '../Components/Customer'
/** just google search  */
<link rel="icon" href="public/favicon.ico"></link>
const HomePage = () => {
  const [showForm, setShowForm] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

console.log(selectedOption)

 

    const handleStartQuote = (selected) => {
      if (selected === 'Personal Auto') {
          setShowForm(true);
          setSelectedOption('Personal Auto');
      }
      else if (selected === 'Business') {
        setSelectedOption('Business');
        
    }

    else if (selected === 'Renters') {
      setShowForm(true);
      setSelectedOption('Renters');
      
      
  }

  else if (selected === 'Comercial Auto') {
    
    setSelectedOption('Comercial Auto');
    
    
}
      
  };

  
   const handleRedirect = () => {
    window.location.href = 'https://app.coterieinsurance.com/quote?p=birimo.insurance%40gmail.com';
    };

    const handleComercialRedirect = () => {
      window.location.href = 'https://forms.office.com/r/NijMzrEiu8';
      };

    useEffect(() => {
      if (selectedOption === 'Business') {
          handleRedirect();}
          else if(selectedOption==='Comercial Auto'){
            handleComercialRedirect()
          }
  }, [selectedOption]);

  const resetForm = () => {
    setShowForm(false);
    setSelectedOption(null);
};
  


  return (
    <div>
    <Navbar onReset={resetForm}/>
    <HeroSection/>

    {!showForm && (
        <>
            <Heading/>
            <CardSection onStartQuote={handleStartQuote}/>
            <CustomerSer/>
            <Ceo/>
            <OfficeTiming/>
            <Location/>
        </>
    )}

    {showForm && selectedOption === 'Personal Auto' && <InfoForm onReset={resetForm}/>}
    {showForm && selectedOption === 'Renters' && <RentersPage onReset={resetForm}/>}
<Footer/>
</div>
  )
}

export default HomePage
